/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql,useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

function SEO( { description, lang, meta, title, canonicalUrl = '', robots = [], schema = [], keywords = '' } ) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  useEffect( () => {
    if ( window ) {
      const links = [{
        rel: 'icon',
        type: 'image/svg+xml',
        href: 'https://public-bubble.s3-us-west-2.amazonaws.com/images/favicon-ptmf6j.svg'
      },
      {
        rel: 'alternate icon',
        type: 'image/png',
        href: 'https://public-bubble.s3-us-west-2.amazonaws.com/images/favicon-u3jyd8.png'
      }];

      const link0 = document.querySelectorAll( 'link' )[0];
      const head = document.querySelector( 'head' );

      links.forEach( link => {
        try{
          const icon = document.createElement( 'link' );
          icon.rel = link.rel;
          icon.type = link.type;
          icon.href = link.href;

          head.insertBefore( icon, link0 );
        } catch( e ) {
          console.log( e );
        }
      } );
    }
  }, [] );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={
        [
          canonicalUrl ? {
            rel: 'canonical',
            href: canonicalUrl
          } : {}
        ]
      }
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author || ''
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0 , user-scalable=no'
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0'
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
        },
        {
          name: 'viewport',
          content: 'width=device-width, height=device-height'
        },
        {
          name: 'viewport',
          content: 'user-scalable=no, width=device-width'
        },
        {
          name: 'og:image',
          content: 'https://cdn.getmybubble.com/images/corporate-sites/bubble.jpg'
        },
        {
          name: 'google-site-verification',
          content: 'PqlbkyvQa6NU_8LYrJOpzMoobuTq0IzT1T3cWWxwUS8'
        },
        robots && robots[1] ?
          {
            name: 'robots',
            content: robots[1]
          } : {},
        robots && robots[2] ?
          {
            name: 'robots',
            content: robots[2]
          } : {},
        keywords ? {
          name: 'keywords',
          content: keywords
        } : {}
      ].concat( meta )}
    >
      {
        schema.length &&
        schema.map( ( scheme, i ) =>
          ( <script key={i} type='application/ld+json'>
            { scheme }
          </script> )
        )
      }
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: ''
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf( PropTypes.object ),
  title: PropTypes.string.isRequired
};

export default SEO;
